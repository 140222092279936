import React from 'react'
import { HiGlobeEuropeAfrica } from "react-icons/hi2";
import { HiSpeakerWave } from "react-icons/hi2";
import { HiMiniWrench } from "react-icons/hi2";
import { HiShieldCheck } from "react-icons/hi";
import { HiLink } from "react-icons/hi2";
import { HiMiniBookOpen } from "react-icons/hi2";
const Mainfeatures = () => {
  return (
    <section id="features" className=" bg-[#11153e] pt-[80px] pb-[25px] md:pb-[50px]">
    <div className=" mx-2 md:mx-[6rem] text-white">
    <div className="flex flex-wrap mx-[-16px]">
    <div className="w-full px-4">
    <div className="mx-auto max-w-[700px] text-center mb-[30px] md:mb-[100px] wow fadeInUp visible animate delay-100" data-wow-delay=".1s" >
    <h2 className=" bg-gradient-to-r from-[#70CBA2] font-bold via-blue-400 to-purple-600 bg-clip-text text-transparent text-2xl sm:text-4xl md:text-[45px] mb-2 md:mb-4">Main Features</h2>
    </div>
    </div>
    </div>
    <div className="flex flex-wrap md:mx-[-16px]">
    <div className="w-full md:w-1/2 lg:w-1/3 px-4">
    <div className="mb-[40px] md:mb-[70px]  visible animate delay-150" data-wow-delay=".15s">
    <div className="w-[70px] h-[70px] flex items-center justify-center rounded-md bg-primary bg-opacity-10 mb-5 md:mb-10 text-primary">
    <HiGlobeEuropeAfrica size='3rem' className='text-[#70CBA2]' />
    </div>
    <h3 className="font-bold text-white dark:text-white text-lg sm:text-2xl lg:text-xl xl:text-2xl mb-5">Global Language Inclusivity</h3>
    <p className="text-body-color text-sm md:text-base leading-relaxed font-thin pr-[10px]">
    AUDINO transcends linguistic boundaries, offering support for a diverse range of languages worldwide, including low-resource languages.
    </p>
    </div>
    </div>
    <div className="w-full md:w-1/2 lg:w-1/3 px-4">
    <div className="mb-[40px] md:mb-[70px]  visible animate delay-200" data-wow-delay=".2s" >
    <div className="w-[70px] h-[70px] flex items-center justify-center rounded-md bg-primary bg-opacity-10 mb-5 md:mb-10 text-primary">
    <HiSpeakerWave  size='3rem' className='text-[#70CBA2]'/>
    </div>
    <h3 className="font-bold text-white dark:text-white text-xl sm:text-2xl lg:text-xl xl:text-2xl mb-5">
  Text & Voice Services</h3>
    <p className="text-body-color text-sm md:text-base leading-relaxed font-thin pr-[10px]">
    Transform your development journey. Recognize intents, identify languages, and more with our AI models at the forefront of innovation.
    </p>
    </div>
    </div>
    <div className="w-full md:w-1/2 lg:w-1/3 px-4">
    <div className="mb-[40px] md:mb-[70px] wow fadeInUp visible animate delay-250" data-wow-delay=".25s" >
    <div className="w-[70px] h-[70px] flex items-center justify-center rounded-md bg-primary bg-opacity-10 mb-5 md:mb-10 text-primary">
    <HiMiniWrench size='3rem' className='text-[#70CBA2]' />
    </div>
    <h3 className="font-bold text-white dark:text-white text-xl sm:text-2xl lg:text-xl xl:text-2xl mb-5">  Swift Solutions</h3>
    <p className="text-body-color text-sm md:text-base leading-relaxed font-thin pr-[10px]">
    Designed for efficiency. AUDINO is your no-code solution, expediting advanced language features without extensive coding.
    </p>
    </div>
    </div>
    <div className="w-full md:w-1/2 lg:w-1/3 px-4">
    <div className="mb-[40px] md:mb-[70px] wow fadeInUp visible animate delay-100" data-wow-delay=".1s">
    <div className="w-[70px] h-[70px] flex items-center justify-center rounded-md bg-primary bg-opacity-10 mb-5 md:mb-10 text-primary">
    <HiShieldCheck size='3rem' className='text-[#70CBA2]' />
    </div>
    <h3 className="font-bold text-white dark:text-white text-xl sm:text-2xl lg:text-xl xl:text-2xl mb-5">Setting Standards</h3>
    <p className="text-body-color text-sm md:text-base leading-relaxed font-thin pr-[10px]">
    AUDINO goes beyond processing – we set new AI standards, shaping the future of language processing innovation.
    </p>
    </div>
    </div>
    <div className="w-full md:w-1/2 lg:w-1/3 px-4">
    <div className="mb-[40px] md:mb-[70px] wow fadeInUp visible animate delay-150" data-wow-delay=".15s">
    <div className="w-[70px] h-[70px] flex items-center justify-center rounded-md bg-primary bg-opacity-10 mb-5 md:mb-10 text-primary">
    <HiLink size='3rem' className='text-[#70CBA2]' />
    </div>
    <h3 className="font-bold text-white dark:text-white text-xl sm:text-2xl lg:text-xl xl:text-2xl mb-5">Solving Challenges</h3>
    <p className="text-body-color text-sm md:text-base leading-relaxed font-thin pr-[10px]">
    Focus on real-world solutions. Our platform handles intricacies, letting developers swiftly bring their ideas to market.
    </p>
    </div>
    </div>
    <div className="w-full md:w-1/2 lg:w-1/3 px-4">
    <div className="mb-[40px] md:mb-[70px] wow fadeInUp visible animate delay-200" data-wow-delay=".2s">
    <div className="w-[70px] h-[70px] flex items-center justify-center rounded-md bg-primary bg-opacity-10 mb-5 md:mb-10 text-primary">
    <HiMiniBookOpen size='3rem' className='text-[#70CBA2]' />
    </div>
    <h3 className="font-bold text-white dark:text-white text-xl sm:text-2xl lg:text-xl xl:text-2xl mb-5">Mission to Democratize NLP</h3>
    <p className="text-body-color text-sm md:text-base leading-relaxed font-thin pr-[10px]">
    We are on a mission to ensure that language processing is accessible to all developers, fostering an environment where solving real-world business challenges takes center stage.
    </p>
    </div>
    </div>
    </div>
    </div>
    </section>
  )
}

export default Mainfeatures





