import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import About from "./components/About";
import Mainfeatures from "./components/Mainfeatures";
import Fetures from "./components/Fetures";


export default function Aboutus() {
    return (
      <>
     <Navbar />
     <About/>
     <Mainfeatures/>
     <Fetures/>
    <Footer />
      </>
    );
  }