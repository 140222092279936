import React from "react";

const Fetures = () => {
  return (
    <section className="bg-[#121747] text-white ">
      <div className=" px-2 py-10 sm:px-4 lg:px-4 lg:py-14 mx-auto">
        <div className="mx-auto text-center mb-10 lg:mb-14">
          <h2 className="text-2xl font-bold md:text-5xl md:leading-tight bg-gradient-to-r from-[#70CBA2]  via-blue-400 to-purple-600 bg-clip-text text-transparent ">
            Services Provided By Us
          </h2>
        </div>

        <div className="max-w-[75rem] mx-auto">
          <div className="grid sm:grid-cols-2 gap-6 mx-3 md:mx-0 md:gap-12">
            <div>
              <h3 className="text-lg font-semibold text-[#70CBA2]">
                Language Understanding
              </h3>
              <p className="mt-2 text-sm font-thin text-gray-300 dark:text-gray-400">
                Whether you're utilising chatbots, voicebots, or process
                automation systems, they all rely on Natural Language
                Understanding (NLU). Its primary purpose is to discern the
                user's intent and extract pertinent information  from
                their spoken or written input in order to
                execute a relevant action. Entities encompass a wide spectrum,
                from general details like names and addresses to highly
                specialised terms such as chemical names or medications. NLU can
                also predict the user's sentiment, enabling the analysis of user
                behaviour or the creation of chatbots and voicebots that respond
                to users with greater empathy.
              </p>
            </div>

            <div>
              <h3 className="text-lg font-semibold text-[#70CBA2]">
                Entity Recognition
              </h3>
              <p className="mt-2 text-sm font-thin text-gray-300 dark:text-gray-400">
                In text documents, there are essential phrases known as entities
                that enhance searchability. These entities can include names of
                individuals, organisations, places, medical codes, time
                references, quantities, monetary figures, and percentages.
                Categorising these elements as entities makes documents more
                accessible for search purposes. However, manually extracting
                these entities from documents for indexing is a highly
                time-consuming and costly process. Moreover, extracting
                domain-specific terms and phrases necessitates specialised
                expertise.
              </p>
            </div>

            <div>
              <h3 className="text-lg font-semibold  text-[#70CBA2]">
                Sentence Generator
              </h3>
              <p className="mt-2 text-sm font-thin text-gray-300 dark:text-gray-400">
                In any language processing task, having enough data is crucial,
                and every machine learning practitioner dreams of a magic way to
                generate more data effortlessly. That's precisely why we created
                the Sentence Generator. This tool takes a single sentence and
                can generate up to ten new sentences while preserving the
                original sentence's meaning and purpose. It's a game-changer for
                speeding up dataset creation and strengthening language
                processing models.
              </p>
            </div>

            <div>
              <h3 className="text-lg font-semibold  text-[#70CBA2]">
                Translation
              </h3>
              <p className="mt-2 text-sm font-thin text-gray-300 dark:text-gray-400">
                When it comes to subtitles, government documents, or exam
                question papers, the need for translation into multiple
                languages is evident. Yet, manually handling such extensive
                translation tasks is not only costly but also incredibly
                time-intensive. Translation is here to transform the way you
                approach this challenge. Our cutting-edge translation models are
                designed to significantly slash the time required for document
                translation. These models are at the forefront of technology,
                capable of translating text seamlessly among over 100 languages.
              </p>
            </div>

            <div>
              <h3 className="text-lg font-semibold  text-[#70CBA2]">
                Speech to Text (STT)
              </h3>
              <p className="mt-2 text-sm font-thin text-gray-300 dark:text-gray-400">
                Speech is the most natural form of human communication. However,
                current AI models often struggle to grasp the full meaning
                behind spoken words as effectively as they do with text. But
                what if there was a bridge that allowed you to use speech as an
                interface while also comprehending its underlying meaning for
                meaningful interactions? Enter the Speech To Text (STT) Service,
                your solution to this challenge. It harnesses state-of-the-art
                AI models to provide precise real-time and file transcriptions
                of all types of speech, whether it's in conversations or other
                formats. Once you have these transcriptions, it essentially
                transforms speech into text, which can then be thoroughly
                understood and interpreted by advanced and well-tested text
                services like Language Understanding. With Speech To Text, you
                can significantly expedite the manual transcription of speech
                audio files, saving you valuable time and effort.
              </p>
            </div>

            <div>
              <h3 className="text-lg font-semibold  text-[#70CBA2]">
                Language Detection
              </h3>
              <p className="mt-2 text-sm font-thin text-gray-300 dark:text-gray-400">
                When your application caters to a multilingual user base, it
                becomes essential to identify the language they are using. This
                not only enhances the user experience but also allows you to
                select language-specific AI models for processing their input
                effectively. For instance, a chatbot can determine the user's
                language and respond in the same language, or an email
                automation system can identify the language of the sender and
                choose an appropriate language-specific AI model for the
                conversation. Our Language Support feature has you covered,
                enabling the detection of over 150 languages directly through
                our APIs. It's important to note that, as of now, we exclusively
                support language detection for text documents.
              </p>
            </div>
            <div>
              <h3 className="text-lg font-semibold  text-[#70CBA2]">
                Text to Speech (TTS)
              </h3>
              <p className="mt-2 text-sm font-thin text-gray-300 dark:text-gray-400">
                Text-to-Speech (TTS), also referred to as voice synthesis or
                Text-to-Voice technology, is the capability to generate spoken
                audio from written text in real-time. NeuralSpace's TTS Service
                allows you to choose synthetic voices tailored to your preferred
                language. Text-To-Speech serves as a valuable tool for filling
                gaps in your multilingual software and extending your global
                reach, ensuring comprehensive coverage worldwide.
              </p>
            </div>
            <div>
              <h3 className="text-lg font-semibold  text-[#70CBA2]">
                Voice Extractor
              </h3>
              <p className="mt-2 text-sm font-thin text-gray-300 dark:text-gray-400">
                In numerous NLP tasks, particularly those involving speech
                within audio files, a common challenge is isolating the voice
                audio from the background audio, which may include music, noise,
                and more. Whether you aim to enhance Speech-to-Text accuracy,
                develop a video localization application, or create a Karaoke
                app, the need for such a service becomes evident. Our Voice
                Extraction Service has been specifically designed to address
                this precise requirement.
              </p>
            </div>
            <div>
              <h3 className="text-lg font-semibold  text-[#70CBA2]">
                Speaker Identification
              </h3>
              <p className="mt-2 text-sm font-thin text-gray-300 dark:text-gray-400">
                Speaker Identification, also known as speech diarization,
                involves the task of distinguishing speakers in audio recordings
                when multiple individuals are present in the same audio file.
                Our Speaker Identification Service offers a streamlined solution
                that allows you to effortlessly identify and separate distinct
                speakers. Simply upload your audio file using our APIs to
                achieve this.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Fetures;
