import React from "react";
import avtarWebp from "../../../assetes/avatar.webp";
import avtar1Webp from "../../../assetes/avatar1.webp";
import avtar2Webp from "../../../assetes/avatar2.webp";
import avtar3Webp from "../../../assetes/avatar3.webp";
import avtar4Webp from "../../../assetes/avatar4.webp";
import { AiFillStar } from "react-icons/ai";

const Testinomial = () => {
  return (
    <section className="bg-[#0F1338]">
      <div className=" py-20 md:py-[10rem] relative overflow-hidden">
        <div
          aria-hidden="true"
          className=" inset-0 absolute h-max w-full m-auto grid grid-cols-2 -space-x-52 opacity-40 dark:opacity-20"
        >
          <div className="blur-[106px]  bg-gradient-to-br from-[#70CBA2] via-blue-400 to-purple-600 "></div>
          <div className="blur-[106px]  h-56 bg-gradient-to-br from-[#70CBA2] via-blue-400 to-purple-600 "></div>
        </div>
        <div className="wow fadeInUp   mb-6 text-center visible">
          <span className=" mb-4 font-thin sm:text-[12px] md:text-sm inline-flex text-center bg-[#0F1338]   pr-5 pl-3  shadow-[#70CBA2] shadow-sm items-center gap-2 py-2 px-4.5 rounded-full">
            <AiFillStar className="text-yellow-500 " />

            <span className="text-white"> Testinomial </span>
          </span>
        </div>

        <div className="max-w-7xl mx-auto  px-6 md:px-12 xl:px-6">
          <div className="  relative   ">
            <div className="flex  items-center justify-center -space-x-2">
              <img
                loading="lazy"
                width="400"
                height="400"
                src={avtarWebp}
                alt="member"
                className="h-8 w-8  rounded-full object-cover"
              />
              <img
                loading="lazy"
                width="200"
                height="200"
                src={avtar1Webp}
                alt="member"
                className="h-12 w-12 rounded-full object-cover"
              />
              <img
                loading="lazy"
                width="200"
                height="200"
                src={avtar3Webp}
                alt="member"
                className="z-10 h-16 w-16 rounded-full object-cover"
              />
              <img
                loading="lazy"
                width="200"
                height="200"
                src={avtar2Webp}
                alt="member"
                className=" h-12 w-12 rounded-full object-cover"
              />
              <img
                loading="lazy"
                width="200"
                height="200"
                src={avtar4Webp}
                alt="member"
                className="h-8 w-8 rounded-full object-cover"
              />
            </div>
            <div className="mt-6 m-auto md:w-8/12 lg:w-7/12">
              <h1 className="text-center text-2xl  lg:text-4xl font-bold text-white dark:text-white md:text-3xl">
                Get Started Now
              </h1>
              <p className="text-center font-thin text-sm md:text-lg text-white dark:text-gray-300 mt-2">
                Be part of millions people around the world using Audino for
                Language AI.
              </p>
              <div className="flex flex-wrap justify-center gap-6 mt-4">
                <a
                  className="block w-full text-center rounded border border-[#70CBA2] bg-[#70CBA2] px-6 md:px-12 py-3 text-md font-medium text-white hover:bg-orange-400  hover:text-white focus:outline-none focus:ring active:text-opacity-75 sm:w-auto"
                  href="https://ai.audino.in/"
                >
                  Get Started
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testinomial;
