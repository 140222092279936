import React from "react";
import hPng from "../../../assetes/home.png";
import { AiFillStar } from "react-icons/ai";
const Hero = () => {
  return (
    <section className="bg-[#0F1338]  text-white">
      <div className="mx-auto  px-4 pt-[4rem] md:pt-20  flex justify-center  ">
        <div className="mx-auto   text-center">
          <div className=" mb-2 text-center  visible">
            <span className="  mb-4 font-thin relative text-[10px] sm:text-sm inline-flex text-center bg-[#0F1338]  pr-3 pl-1  sm:pr-5 sm:pl-3  shadow-[#70CBA2] shadow-sm items-center gap-2 px-2 py-1 sm:py-2 sm:px-4 rounded-full">
              <AiFillStar className="text-yellow-500 " />

              <span className="text-white"> Innovation With Audino AI </span>
            </span>
          </div>
          <div className="relative">
            <h1 className="bg-gradient-to-r from-[#70CBA2] via-blue-400 to-purple-600 bg-clip-text text-3xl font-extrabold text-transparent sm:text-5xl md:text-7xl pb-2">
              Fueling Innovation with <br />Language AI.
            </h1>

            <p className="mx-auto mt-4 max-w-xl sm:text-xl">
              Unlock the Potential of Video, Speech, Text AI Across Languages
              for Your Next Project.
            </p>

            <div className="mt-8 flex flex-wrap justify-center gap-4">
              <a
                className=" w-full rounded border border-[#70CBA2] bg-[#70CBA2] px-5 py-3 sm:px-12 sm:py-3 text-lg font-medium text-white hover:bg-orange-400  hover:text-white focus:outline-none focus:ring active:text-opacity-75 cursor-pointer sm:w-auto"
                href="https://ai.audino.in/"
              >
                Get Started
              </a>

              <a
                className=" w-full rounded cursor-pointer border border-[#70CBA2] px-3 py-3 sm:px-12 sm:py-3  text-lg font-medium text-white hover:bg-orange-400  focus:outline-none focus:ring active:bg-[#70CBA2] sm:w-auto"
                href="https://github.com/midas-research/audino/tree/main"
              >
                Get Code
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className=" justify-center relative hidden sm:flex  p-5 md:px-20  ">
        <img src={hPng} alt="/" className=" w-40% h-40% md:w-70% md:h-70%" />
      </div>
    </section>
  );
};

export default Hero;
