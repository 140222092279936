

import {BrowserRouter,Routes,Route} from "react-router-dom";
import Home from "./Pages/Home/Home";
import Aboutus from "./Pages/Aboutus/Aboutus"
import Contactus from "./Pages/Contactus/Contactus"
import Custommodal from './Pages/CustomModal/Custommodal'

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<Aboutus />} />
        <Route path="/contact" element={<Contactus />} />
        <Route path="/custommodal" element={<Custommodal />} />
        
        

        
      
    
      
      </Routes>
    </BrowserRouter>
  );
}

export default App;