import React, { useState } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import countryCodeOptions from "../../../components/countryCodeOptions";

const Custom = () => {
  const [formData, setFormData] = useState({
    name: "",
    organisation: "",
    country_code: "+91",
    mobile: "",
    email: "",
    meeting_time: "",
    meeting_date: "",
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [meetingDate, setMeetingDate] = useState(new Date());
  const [meetingTime, setMeetingTime] = useState(new Date());

  const [, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

  const handleChange = (e) => {
    if (e.target.name === "email") {
      setEmail(e.target.value);
      const isValid = emailRegex.test(e.target.value);
      setIsValidEmail(isValid);
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      [e.target.name]: e.target.value,
    }));
  };

  const handleDateChange = (date) => {
    setMeetingDate(date);
    setFormData((prevFormData) => ({
      ...prevFormData,
      meeting_date: date,
    }));
  };
  const handleTimeChange = (time) => {
    setMeetingTime(time);
    setFormData((prevFormData) => ({
      ...prevFormData,
      meeting_time: time,
    }));
  };

  const handleSubmit = () => {
    console.log("button click");
    if (
      !formData.name ||
      !formData.organisation ||
      !formData.country_code ||
      !formData.mobile ||
      !formData.email ||
      !formData.meeting_date ||
      !formData.meeting_time ||
      !formData.message
    ) {
      alert("Please fill in all fields.");
      return;
    }
    setIsLoading(true);

    axios
      .post("https://video-ai.audino.in/api/custom-contact/create", formData)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        alert(response.data.msg);

        setFormData({
          name: "",
          organisation: "",
          country_code: "+91",
          mobile: "",
          email: "",
          meeting_time: "",
          meeting_date: "",
          message: "",
        });
      })
      .catch((error) => {
        alert(error.response.data.msg);
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <section className="bg-[#0F1338] dark:bg-gray-900 ">
      <div className="max-w-7xl mx-auto  px-8  text-[#011c2b] py-20">
        <div className="md:text-center">
        <h2 className="bg-gradient-to-r from-[#70CBA2] via-blue-400 to-purple-600 bg-clip-text font-extrabold text-transparent text-4xl md:text-5xl py-3">
            Empower Your Solutions with Our Expert Team
          </h2>
          <p className="text-lg font-thin lg:mx-10 text-gray-300 mt-3">
            At Audino, we thrive on challenges and believe in turning problems
            into opportunities. If you have a unique problem statement or a
            project that requires a tailored solution, our expert team is here
            to assist you.
          </p>
        </div>
        <div className="grid lg:grid-cols-3 items-center gap-4 p-4 outline outline-white rounded-lg mt-12">
          <div className="bg-[#22295B] relative rounded-lg p-6 max-lg:text-center">
            <h2 className="text-[#70CBA2] font-bold py-4 text-xl">
              How We Can Help:
            </h2>
            <div className=" text-left ">
              <p className="text-gray-300 font-thin text-sm mb-4">
                <span className="text-lg font-normal  px-1">
                  Model Training:
                </span>
                Provide us with your model, and our expert team will handle the
                training process on your behalf.
              </p>
              <p className="text-gray-300 font-thin text-sm mb-4">
                <span className="text-lg font-normal  px-1 ">
                  Problem Solving:
                </span>
                Have a specific problem statement? Let us tackle it directly. We
                specialize in finding innovative solutions to diverse
                challenges.
              </p>
              <p className="text-gray-300 font-thin text-sm mb-4">
                <span className="text-lg font-normal  px-1">
                  Customized Models:
                </span>
                Tailor the model according to your precise requirements. We
                understand that every problem is unique, and our team is ready
                to customize models to meet your needs.
              </p>
            </div>

          </div>
          <div className="p-6 rounded-xl lg:col-span-2">
            <form>
              <div className="grid sm:grid-cols-2 gap-8">
                <div className=" flex  relative  items-center">
                  <input
                    type="text"
                    placeholder=" Name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    className="px-2 py-4 bg-transparent text-gray-200 w-full text-sm border-b-2 focus:border-[#70CBA2] outline-none"
                  />
                </div>
                <div className=" flex  relative items-center">
                  <input
                    type="text"
                    placeholder="Organisation you are from"
                    name="organisation"
                    value={formData.organisation}
                    onChange={handleChange}
                    className="px-2 py-4 bg-transparent text-gray-200 w-full text-sm border-b-2 focus:border-[#70CBA2] outline-none"
                  />
                </div>
                <div className=" flex  relative items-center">
                  {/* You can use a dropdown for country code */}
                  <select
                    name="country_code"
                    value={formData.country_code}
                    onChange={handleChange}
                    className="px-2 py-[17px] bg-transparent text-gray-200 w-20 text-sm border-b-2 focus:border-[#70CBA2] outline-none"
                  >
                    {countryCodeOptions.map((e) => (
                      <option key={e.dial_code} value={`${e.dial_code}`}>
                        {`${e.dial_code} ${e.code}`}
                      </option>
                    ))}
                    {/* Add more country code options as needed */}
                  </select>
                  <input
                    type="tel"
                    placeholder="Phone No."
                    name="mobile"
                    value={formData.mobile}
                    onChange={handleChange}
                    className="px-2 py-4 bg-transparent text-gray-200 w-full text-sm border-b-2 focus:border-[#70CBA2] outline-none"
                  />
                </div>
                <div className=" flex  relative items-center">
                  <input
                    type="email"
                    placeholder="Email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className={`${
                      isValidEmail
                        ? "border-b-2 focus:border-[#70CBA2]"
                        : "border-b-2 border-red-500"
                    } px-2  py-4  bg-transparent text-gray-200 w-full text-sm outline-none`}
                  />

                  {!isValidEmail && (
                    <p className="text-red-500  font-thin text-[10px]">
                      Please enter a valid email address.
                    </p>
                  )}
                </div>
                <div className=" flex  relative items-center">
                  <DatePicker
                    selected={meetingDate}
                    onChange={handleDateChange}
                    name="meeting_date"
                    placeholderText="Meeting Date (dd/mm/yyyy)"
                    dateFormat="dd/MM/yyyy"
                    className="px-2 py-4 bg-transparent text-gray-200 w-full text-sm border-b-2 focus:border-[#70CBA2] outline-none"
                  />
                </div>
                <div className=" flex relative items-center">
                  <DatePicker
                    selected={meetingTime}
                    onChange={handleTimeChange}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    type="time"
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    placeholderText="Meeting Time"
                    className="px-2 py-4  bg-transparent text-gray-200 w-full text-sm border-b-2 focus:border-[#70CBA2] outline-none"
                  />
                </div>

                <div className=" flex relative items-center sm:col-span-2">
                  <textarea
                    placeholder="Your Problem Statement"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    className="px-2 pt-3 bg-transparent text-gray-200 w-full text-sm border-b-2 focus:border-[#70CBA2] outline-none"
                  ></textarea>
                </div>
              </div>
              <button
                type="button"
                onClick={handleSubmit}
                disabled={isLoading}
                className="mt-12 flex items-center justify-center text-sm lg:ml-auto max-lg:w-full rounded px-4 py-2.5 font-semibold bg-[#70CBA2] text-white hover:bg-orange-400"
              >
                {isLoading ? (
                  "Submiting..."
                ) : (
                  <>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16px"
                      height="16px"
                      fill="#fff"
                      className="mr-2"
                      viewBox="0 0 548.244 548.244"
                    >
                      <path
                        fillRule="evenodd"
                        d="M392.19 156.054 211.268 281.667 22.032 218.58C8.823 214.168-.076 201.775 0 187.852c.077-13.923 9.078-26.24 22.338-30.498L506.15 1.549c11.5-3.697 24.123-.663 32.666 7.88 8.542 8.543 11.577 21.165 7.879 32.666L390.89 525.906c-4.258 13.26-16.575 22.261-30.498 22.338-13.923.076-26.316-8.823-30.728-22.032l-63.393-190.153z"
                        clipRule="evenodd"
                        data-original="#000000"
                      />
                    </svg>
                    Submit
                  </>
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Custom;
