import React, { useState } from "react";
import logoPng from "../assetes/logo.png";
import { NavLink } from "react-router-dom";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";

const Navbar = () => {
  // State to manage the navbar's visibility
  const [nav, setNav] = useState(false);

  // Toggle function to handle the navbar's display
  const handleNav = () => {
    setNav(!nav);
  };

  // Array containing navigation items
  const navItems = [
    { id: 1, text: "Home", path: "/" },
    { id: 2, text: "About", path: "/about" },
    { id: 3, text: "Contact ", path: "/contact" },
  ];

  return (
    <nav className=" bg-[#0F1338] outline outline-[.05px] outline-gray-600 ">
      <div className="   flex justify-between items-center h-20   mx-auto max-w-screen-xl  px-10  text-white  ">
        {/* Logo */}
         <a href="/">
        <img src={logoPng} alt="/" className="h-10" />
        </a>

        {/* Desktop Navigation */}
        <ul className="hidden md:flex  ">
          {navItems.map((item) => (
            <NavLink
              to={item.path}
              // activeClassName="text-black"
              type="li"
              key={item.id}
              className={({ isActive }) =>
                `px-4 py-2 hover:bg-[#70CBA2] rounded-xl m-2 cursor-pointer duration-300  ${
                  isActive ? "bg-[#70CBA2]" : ""
                }`
              }
            >
              {item.text}
            </NavLink>
          ))}
        </ul>

        {/* Mobile Navigation Icon */}
        <div onClick={handleNav} className="block md:hidden">
          {nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
        </div>

        {/* Mobile Navigation Menu */}
        <ul
          className={
            nav
              ? "fixed md:hidden left-0 top-0 w-[60%] h-full border-r border-r-gray-900 z-50 bg-[#000300ad] ease-in-out duration-500"
              : "ease-in-out w-[60%] duration-500 fixed top-0 bottom-0 left-[-100%]"
          }
        >
          {/* Mobile Logo */}
          <div className="m-4">
           
              
            <img src={logoPng} alt="/" className="h-10  px-2 " />
         
          </div>

          {/* Mobile Navigation Items */}
          {navItems.map((item) => (
            <li
              key={item.id}
              className="p-4 border-b rounded-xl hover:bg-[#70CBA2] duration-300 hover:text-black cursor-pointer border-gray-600"
            >
              <NavLink to={item.path} activeClassName="text-black">
                {item.text}
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
