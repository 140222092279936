import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import Custom from "./components/Custom";

export default function Home() {
    return (
      <>
     <Navbar />
    <Custom />
    <Footer />
      </>
    );
  }