import Navbar from "../../components/Navbar";

import Contact from "./components/Contact";



export default function Contactus() {
    return (
      <>
     <Navbar />
     <Contact/>
  
      </>
    );
  }