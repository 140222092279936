import React from "react";
import logoPng from "../assetes/logo.png";

const Footer = () => {
  return (
    <section className="bg-[#0F1338] text-white">
      <footer aria-labelledby="footer-heading" className=" bg-[#0F1338] ">
        <h2 id="footer-heading" className="sr-only">
          Footer
        </h2>
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="py-20">
            <div className="grid grid-cols-1 md:grid-flow-col md:auto-rows-min md:grid-cols-12 md:gap-x-8 md:gap-y-16 mb-8">
              <div className="col-span-1 md:col-span-4 lg:col-start-1 lg:row-start-1">
                <img src={logoPng} alt="" className="h-10 w-auto" />
              </div>

              <div className="  col-span-6 mt-10 grid  sm:grid-cols-2 md:col-span-6 md:col-start-3 md:row-start-1 md:mt-0 lg:col-span-6 lg:col-start-2 lg:ml-10">
                <div>
                  <h3 className="text-sm font-medium text-white-900">
                    Site Maps
                  </h3>

                  <ul className="mt-6 space-y-6">
                    <li className="text-sm">
                      <a
                        href="https://app.audino.in/"
                        className="text-gray-300 font-thin hover:text-gray-400"
                      >
                        Audino annotation tool
                      </a>
                    </li>
                    <li className="text-sm">
                      <a
                        href="https://github.com/midas-research/audino/tree/main"
                        className="text-gray-300 font-thin hover:text-gray-400"
                      >
                        Code
                      </a>
                    </li>
                    <li className="text-sm">
                      <a
                        href="https://ai.audino.in/"
                        className="text-gray-300 font-thin hover:text-gray-400"
                      >
                        Audino AI
                      </a>
                    </li>
                    <li className="text-sm">
                      <a
                        href="/custommodal"
                        className="text-gray-300 font-thin hover:text-gray-400"
                      >
                        Datasets
                      </a>
                    </li>
                    <li className="text-sm">
                      <a
                        href="/custommodal"
                        className="text-gray-300 font-thin hover:text-gray-400"
                      >
                        Model Customization
                      </a>
                    </li>
                  </ul>
                </div>
                <div className=" mt-6 sm:mt-0">
                  <h3 className=" flex text-sm font-medium text-white-900">
                    Links
                  </h3>
                  <ul className="mt-6 space-y-6">
                    <li className="text-sm">
                      <a
                        href="/"
                        className="text-gray-300 font-thin hover:text-gray-400"
                      >
                        Home
                      </a>
                    </li>
                    <li className="text-sm">
                      <a
                        href="/about"
                        className="text-gray-300 font-thin hover:text-gray-400"
                      >
                        About
                      </a>
                    </li>
                    <li className="text-sm">
                      <a
                        href="/contact"
                        className="text-gray-300 font-thin hover:text-gray-400"
                      >
                        Contact
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="mt-12 md:col-span-8 md:col-start-3 md:row-start-2 md:mt-0 lg:col-span-4 lg:col-start-9 lg:row-start-1">
                <h3 className="text-xl font-medium text-white">
                  Have Questions?
                </h3>
                <p className="mt-6 text-sm font-thin text-gray-200">
                  We're just a message away. Ask us anything you need to know.
                </p>
                <form className="mt-2 flex sm:max-w-md">
                  <div className="flex justify-center items-center">
                    <a
                      href="/contact"
                      className="flex w-full items-center justify-center rounded-md border hover:border-orange-400 bg-[#70CBA2] hover:bg-orange-400 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-secondary focus:outline-none focus:ring-2 focus:secondary focus:ring-offset-2"
                    >
                      Contact Us
                    </a>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className="pb-10 text-center">
            <p className="text-md   text-white">
              Contact Us:
              <a href="mailto:contact@audino.in">contact@audino.in</a>
            </p>
          </div>
          <div className="border-t border-gray-100 py-10 text-center">
            <p className="text-sm text-gray-500">
              © 2024 SigSail. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    </section>
  );
};

export default Footer;
