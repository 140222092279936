import React from "react";

import worldSvg from "../../../assetes/world.svg";

function Languages({ text }) {
  return (
    <div className="pt-10 flex gap-4 md:items-center">
      <div className="w-12 h-12 flex gap-4 rounded-full bg-indigo-100 dark:bg-indigo-900/20">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          className="w-6 h-6 m-auto text-indigo-500 dark:text-indigo-400"
        >
          <path
            fillRule="evenodd"
            d="M4.848 2.771A49.144 49.144 0 0112 2.25c2.43 0 4.817.178 7.152.52 1.978.292 3.348 2.024 3.348 3.97v6.02c0 1.946-1.37 3.678-3.348 3.97a48.901 48.901 0 01-3.476.383.39.39 0 00-.297.17l-2.755 4.133a.75.75 0 01-1.248 0l-2.755-4.133a.39.39 0 00-.297-.17 48.9 48.9 0 01-3.476-.384c-1.978-.29-3.348-2.024-3.348-3.97V6.741c0-1.946 1.37-3.68 3.348-3.97zM6.75 8.25a.75.75 0 01.75-.75h9a.75.75 0 010 1.5h-9a.75.75 0 01-.75-.75zm.75 2.25a.75.75 0 000 1.5H12a.75.75 0 000-1.5H7.5z"
            clipRule="evenodd"
          ></path>
        </svg>
      </div>
      <div className="w-5/6">
        <h4 className=" text-lg text-white dark:text-indigo-300">{text}</h4>
      </div>
    </div>
  );
}
const Language = () => {
  return (
    <section className="bg-[#0F1338]">
      <div
        id="solution"
        className="max-w-7xl mx-auto px-6 md:px-12 xl:px-6 pb-10 pt-[5rem] lg:pt-[10rem]"
      >
        <div className="space-y-6 justify-between text-gray-600 md:flex flex-row-reverse md:gap-6 md:space-y-0 lg:gap-12 lg:items-center">
          <div className="md:w-7/12 lg:w-1/2">
            <h2 className="text-3xl font-bold text-white md:text-4xl dark:text-white">
              Expand Your Reach Globally with Linguistic Diversity
            </h2>
            <p className="mt-8 text-white font-thin dark:text-gray-300">
              Our AI services can translate and understand over 100 languages,
              including Hindi, and languages that mix multiple languages. See
              the full list of languages we support.
            </p>
            <div className="divide-y space-y-4 divide-gray-600 dark:divide-gray-800">
              <Languages text="Hindi" />
              <Languages text="English" />
              <Languages text="Tamil" />
              <Languages text="Bangla" />
              <Languages text="Telugu" />
              <Languages text="more..." />
            </div>
          </div>
          <div className="md:w-5/12 lg:w-1/2 py-10 pr-14">
            <img src={worldSvg} alt="world" loading="lazy" className="w-full" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Language;
